.icon-branch {
	width: 88.83px;
	height: 82.01px;
}

.icon-clock {
	width: 13.15px;
	height: 13.15px;
}

.icon-cup {
	width: 53.892px;
	height: 53.892px;
}

.icon-play {
	width: 48px;
	height: 55px;
}

.icon-quotes-b {
	width: 37.26px;
	height: 27px;
}

.icon-quotes-t {
	width: 37.26px;
	height: 27px;
}

.icon-star {
	width: 55.525px;
	height: 53.014px;
}

